import { Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { Element } from "react-scroll";
export interface ContainerProps {
	children?: ReactNode;
	className?: string;
	anchorName?: string;
	backgroundImage?: string;
	backgroundColor?: string;
	backgroundBlend?: boolean;
	maxWidth?: string;
	addHeaderPadding?: boolean;
	noXPadding?: boolean;
}

export function Container({
	children,
	className,
	backgroundImage,
	backgroundColor,
	backgroundBlend,
	maxWidth,
	anchorName,
	addHeaderPadding = false,
	noXPadding = false,
}: ContainerProps) {
	return (
		<Box
			as={Element}
			name={anchorName}
			className={className}
			backgroundColor={backgroundColor}
			width="100%"
			id={anchorName}
			px={!noXPadding ? "20px" : null}
      position="relative"
		>
			<Box
				backgroundImage={backgroundImage}
				backgroundPosition="center"
				backgroundRepeat="no-repeat"
				backgroundSize="cover"
				position="absolute"
				width="100%"
				mx="auto"
				left={0}
				right={0}
				top={0}
				bottom={0}
				mixBlendMode={backgroundBlend ? "multiply" : "normal"}
			/>
			<Box
				mx="auto"
				pt={addHeaderPadding ? "100px" : null}
				maxWidth={maxWidth}
				width="100%"
			>
				{children}
			</Box>
		</Box>
	);
}
